<template>
	<nav class="student__footer secondary-contrast-background">
		<v-container>
			<v-row class="centered">
				<v-col cols="12">
					<div class="student__footer--wrapper">
						<figure><img id="logo" :src="logoSrc" /></figure>
						<ul class="student__footer--list">
							<li>
								<router-link to="/student/privacy-policy"
									>Privacy Policy</router-link
								>
							</li>
							<li>
								<router-link to="/student/courses"
									>Terms of Service</router-link
								>
							</li>
						</ul>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</nav>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "StudentFooter",
	data() {
		return {
			userAvatar: "https://cdn.vuetifyjs.com/images/john.jpg",
			logoSrc: ""
		};
	}
};
</script>

<style></style>
